import React, { useCallback } from "react";
import classNames from "classnames";
import css from "./FamilyNode.module.css";
import { Avatar } from "@mantine/core";

interface FamilyNodeProps {
  node: any;
  isRoot: boolean;
  image?: string;
  isHover?: boolean;
  onClick: (id: string) => void;
  onSubClick: (id: string) => void;
  style?: React.CSSProperties;
}

export const FamilyNode = React.memo(function FamilyNode({
  node,
  isRoot,
  isHover,
  onClick,
  image,
  onSubClick,
  style,
}: FamilyNodeProps) {
  const clickHandler = useCallback(() => onClick(node.id), [node.id, onClick]);
  const clickSubHandler = useCallback(
    () => onSubClick(node.id),
    [node.id, onSubClick]
  );

  console.log("zxcnode", node);
  return (
    <div className={css.root} style={style}>
      <div
        className={classNames(
          css.inner,
          css[node.gender],
          isRoot && css.isRoot,
          isHover && css.isHover
        )}
        onClick={clickHandler}
      >
        <Avatar src={"https://" + image} />
        <div className={css.id}>{node.id}</div>
        <div style={{marginTop:2}} className={css.id}>
          {node?.birth_date !== null
            ? node?.birth_date?.substring(0, 4)
            : "" + " - " + node?.death_date !== null
            ? node?.death_date?.substring(0, 4)
            : ""}
        </div>
      </div>
      {node.hasSubTree && (
        <div
          className={classNames(css.sub, css[node.gender])}
          onClick={clickSubHandler}
        />
      )}
    </div>
  );
});
