import { Route, Routes } from "react-router-dom";
import InvitePage from "./pages/invitePage/InvitePage";
import LoginPage from "./pages/auth/LoginPage/LoginPage";
import { AuthProvider } from "./utils/useAuth";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { UnProtectedRoute } from "./utils/unProtectedRoute";
import HomePage from "./pages/HomePage/HomePage";
import RegisterPage from "./pages/auth/RegisterPage/RegisterPage";
import NotificationPage from "./pages/NotificationPage/NotificationPage";
import MessagesPage from "./pages/MessagesPage/MessagesPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import MorePage from "./pages/MorePage/MorePage";
import NewRecord from "./pages/NewRecord/NewRecord";
import MemberProfilePage from "./pages/MemberProfilePage/MemberProfilePage";
import FamilyTreeMobile from "./pages/FamilyTree/FamilyTreeMobile";
import ForgotPasswordPage from "./pages/auth/ForgotPassword/ForgotPasswordPage";

function App() {
  return (
    <AuthProvider>
      <Routes>
        {/* <Route
          path="/login"
          element={
            <UnProtectedRoute>
              <LoginPage />
            </UnProtectedRoute>
          }
        /> */}
        {/* <Route
          path="/register"
          element={
            <UnProtectedRoute>
              <RegisterPage />
            </UnProtectedRoute>
          }
        /> */}
        {/* <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notification"
          element={
            <ProtectedRoute>
              <NotificationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/messages"
          element={
            <ProtectedRoute>
              <MessagesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:id"
          element={
            <ProtectedRoute>
              <MemberProfilePage/>
            </ProtectedRoute>
          }
        />
       
        <Route
          path="/my/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
         <Route
          path="/family/new-record"
          element={
            <ProtectedRoute>
              <NewRecord />
            </ProtectedRoute>
          }
        />
        <Route
          path="/more"
          element={
            <ProtectedRoute>
              <MorePage />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/family-tree/:family_id/:token"
          element={<FamilyTreeMobile />}
        />
        <Route path="/invite" element={<InvitePage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
