import QueryString from "qs";
import { UseAuth } from "../../utils/useAuth";
import restClient from "../restClient";

export const getUpcomingAnniversariesFN = async () => {
  try {
    const token = localStorage.getItem("token")?.replace(/"/g, "");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get("/upcoming-anniversaries", config);
    return {
      data: data.data,
    };
  } catch (error) {
    console.log("zxcerror", error);
  }
};

export const getUserFamilyBondFN = async () => {
  try {
    const token = localStorage.getItem("token")?.replace(/"/g, "");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get('/family-bonds', config);
    return {
      data: data.data,
    };
  } catch (error) {
    console.log('zxcerror', error);
  }
};
export const getFamilyTree = async (id: number) => {
  try {
    const token = localStorage.getItem("token")?.replace(/"/g, "");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get(`/family-tree/${id}`, config);
    console.log("zxcdfammata", data.data);
    return data.data;
  } catch (error) {
    console.log("zxcerror", error);
  }
};
export const getSearchMembers = async (search: string) => {
  console.log("zxcsearch",search)
  try {
    const token = localStorage.getItem("token")?.replace(/"/g, "");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get(`/family-members?filters[displayName][$contains]=${search}`, config);
    console.log("searchdata",data);
    return data.data;
  } catch (error) {
    console.log("zxcerror", error);
  }
};
export const getFamilyTreeMobile = async (id: number,token:string) => {
  console.log("zxcid,token",id,token)
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await restClient.get(`/family-tree/${id}`, config);
    console.log("zxcdfammata", data.data);
    return data.data;
  } catch (error) {
    console.log("zxcerror", error);
  }
};

export const getFamilyMembersFn = async (id: number) => {
  try {
    const token = localStorage.getItem("token")?.replace(/"/g, "");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await restClient.get(
      `/family-members?filters[family_id]=${id}`,
      config
    );
    return {
      data: data.data,
    };
  } catch (error) {
    console.log("zxcerror", error);
  }
};

export const getMemberFn = async (uid: any) => {
  try {
    const token = localStorage.getItem("token")?.replace(/"/g, "");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await restClient.get(`/member/${uid}`, config);
    console.log("zxcdfammata", data.data);
    return data.data;
  } catch (error) {
    console.log("zxcerror", error);
  }
};

export const addnewFamilyFN = async (data: any) => {
  try {
    const token = localStorage.getItem("token")?.replace(/"/g, "");

    const newData: any = {};
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    const formData: any = new FormData();

    newData.name = data.name;
    newData.surname = data.surname;
    newData.biography = data.biography;
    newData.date_death = data.date_death;
    newData.public = data.isPublic;
    newData.location = data.location;
    newData.family_id = data.family_id;
    newData.birth_date = data.birth_date;
    newData.creater_user = data.creater_user;

    // console.log('newData', newData);
    // formData.append('files.avatar', data.avatar);
    if (data.avatar !== undefined) {
      formData.append("files.avatar", data.avatar);
    }

    // if (data.banner_image !== undefined) {
    //   formData.append('files.avatar', {
    //     uri: data.banner_image.uri,
    //     type: data.banner_image.type,
    //     name: data.banner_image.fileName,
    //   });
    // }
    if (data.banner_image !== undefined) {
      formData.append("files.banner_image", data.banner_image);
    }
    console.log("zxcdata.bioImages",data.bioImages)
    if (data.bio_images !== undefined) {
      formData.append("files.bio_images",data.bio_images);
    }

    if (data.cemetery_images !== undefined) {
      formData.append("files.cemetery_images",data.cemetery_images);
    }

    // formData.append('files.cemetery_images', data.cemetery_images);
    formData.append("data", JSON.stringify(newData));
    const response = await restClient.post("/family-members", formData, config);
    console.log("response-New Family", response, "DATA---", data);
    // if (response) {
    //   const response = await restClient.put(
    //     '/family-members' + response.data.data.id,
    //     formData2,
    //     config,
    //   );
    // }
    return {
      data: response.data,
    };
  } catch (error) {
    return error;
  }
};
