import axios from 'axios';

const baseUrl = 'https://exelife-3b16a4f0fb3e.herokuapp.com/api';
// const baseUrl = 'http://localhost:1337/api';

export default axios.create({
  baseURL: baseUrl,
  responseType: 'json',
  //60 sec timeout
  timeout: 60000,

  //follow up to 10 HTTP 3xx redirects
  maxRedirects: 10,

  //cap the maximum content length we'll accept to 50MBs, just in case
  maxContentLength: 50 * 1000 * 1000,
});
