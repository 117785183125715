import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addnewFamilyFN, getFamilyMembersFn, getFamilyTree, getFamilyTreeMobile, getMemberFn, getSearchMembers, getUpcomingAnniversariesFN, getUserFamilyBondFN } from "../api";

export const useUpcomingAnniversaries = () => {
  const { data, isLoading, isSuccess,isError, error } = useQuery({
    queryKey: ['upcoming-anniversaries'],
    queryFn: () => getUpcomingAnniversariesFN(),
  });
  return { data, isLoading,isSuccess, isError, error };
};

export const useGetFamilyTree = (id:number) => {
  const { data, isLoading, isSuccess,isError, error } = useQuery({
    queryKey: ['family-tree-',id],
    queryFn: () => getFamilyTree(id),
  });

  return { data, isLoading,isSuccess, isError, error };
};

export const useGetFamilyBond = () => {
  const {data, isLoading, isSuccess, isError, error} = useQuery({
    queryKey: ['family-bonds'],
    queryFn: () => getUserFamilyBondFN(),
    // staleTime: 3600000,
  });

  return {data, isLoading, isSuccess, isError, error};
};

export const useGetSearchMembers = (search:string) => {
  const { data, isLoading, isSuccess,isError, error } = useQuery({
    queryKey: ['search'],
    queryFn: () => getSearchMembers(search),
  });

  return { data, isLoading,isSuccess, isError, error };
};

export const useGetFamilyTreeMobile = (id:number,token:string) => {
  const { data, isLoading, isSuccess,isError, error } = useQuery({
    queryKey: ['family-tree-',id],
    queryFn: () => getFamilyTreeMobile(id,token),
  });

  return { data, isLoading,isSuccess, isError, error };
};
export const useGetFamilyMembers = (id:number) => {
  const { data, isLoading, isSuccess,isError, error } = useQuery({
    queryKey: ['family-members-',id],
    queryFn: () => getFamilyMembersFn(id),
  });

  return { data, isLoading,isSuccess, isError, error };
};
export const useGetFamilyMember = (uid:number) => {
  const { data, isLoading, isSuccess,isError, error } = useQuery({
    queryKey: ['family-members-',uid],
    queryFn: () => getMemberFn(uid),
  });

  return { data, isLoading,isSuccess, isError, error };
};

export const useAddnewFamily = () => {
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
    isIdle,
    mutate: addNewFamily,
  } = useMutation((data:any) => addnewFamilyFN(data), {
    onError: err => {
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['family'],
      });
      queryClient.invalidateQueries({
        queryKey: ['upcoming-anniversaries'],
      });
    },
  });

  return {data, isLoading, isSuccess, isError, error, addNewFamily};
};
