import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { APIProvider } from "@vis.gl/react-google-maps";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();
root.render(
  <BrowserRouter>
  <QueryClientProvider client={queryClient}>

    <MantineProvider withGlobalStyles withNormalizeCSS>
    <APIProvider apiKey={"AIzaSyBq9xE89S0YbMP3RNItwIyDP_ehFMwGQzY"} libraries={['marker']}>
      <App />
      </APIProvider>
    </MantineProvider>
  </QueryClientProvider>
  </BrowserRouter>
);
