import React, { useState } from "react";
import FamilyTreeMobileComp from "../../components/low-level/family-tree/FamilyTreeMobile";
import { useParams } from "react-router-dom";
import { useGetFamilyTree, useGetFamilyTreeMobile } from "../../api/family/service";

const FamilyTreeMobile = ({ props }: any) => {
  let { family_id ,token}: any = useParams();
  const { data, isLoading } = useGetFamilyTreeMobile(family_id,token);

  return (
    <div style={{ height: "100%", flexDirection: "column" }}>
      <FamilyTreeMobileComp data={data} />
    </div>
  );
};

export default FamilyTreeMobile;
