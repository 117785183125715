import {
  Button,
  Card,
  Center,
  Flex,
  Image,
  PasswordInput,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import restClient from "../../../api/restClient";
import { useSearchParams } from "react-router-dom";
import { error } from "console";

const ForgotPasswordPage = ({ props }: any) => {
  const [searchParams]: any = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);
  console.log("zxcurlParams", urlParams);
  const { classes } = useStyles();
  const [success, setSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errors, setError]: any = useState();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const submit = async () => {
    setSubmitLoading(true);
    try {
      await restClient
        .post(`/auth/reset-password`, {
          code: urlParams.code,
          password: password,
          passwordConfirmation: passwordConfirmation,
        })
        .then((response) => {
          setSubmitLoading(false);
          setSuccess(true);
          console.log("zxcregister1", response);
        })
        .catch((error) => {
          setSubmitLoading(false);
          setError("This reset has been used before");
          console.log("zxcerror", error);
        });
    } catch (e) {
      console.log("zxce", e);
    }
  };

  useEffect(() => {
    if (password === passwordConfirmation) {
      if (password.length < 6 || passwordConfirmation.length < 6) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [password, passwordConfirmation]);
  return (
    <Center h="100vh">
      <Card
        className={classes.card}
        shadow="sm"
        padding="lg"
        bg={success ? "green" : "white"}
        radius="md"
        withBorder
      >
        <Center>
          <Flex direction="column" align="center">
            <Image
              fit="contain"
              height={"20vh"}
              src={require("../../../assets/logo.png")}
            />
            {!success &&
            <Title fz={20}>Forgot Password</Title>
            }
          </Flex>
        </Center>
        {success ? (
          <Center mt={20}>
            <Text fw={600} color="white">
              Congratulations, your registration has been created successfully.
            </Text>
          </Center>
        ) : (
          <Flex gap={15} direction="column">
            <PasswordInput
              mt={10}
              placeholder="New Password"
              label="New Password (min 6 charaters)"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              withAsterisk
            />
            <PasswordInput
              mb={10}
              placeholder="New Password Confirmation"
              label="New Password Confirmation"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              withAsterisk
            />
            {errors?.length > 0 && (
              <Text color="red">An error occurred, try resetting again.</Text>
            )}
            <Button
              onClick={submit}
              disabled={disabled || submitLoading}
              loading={submitLoading}
              color="green"
              h={60}
              mt={20}
            >
              Send
            </Button>
          </Flex>
        )}
      </Card>
    </Center>
  );
};
const useStyles = createStyles((theme) => ({
  card: {
    width: "40vw",
    [`@media (max-width: 978px)`]: {
      width: "90vw",
    },
  },
}));
export default ForgotPasswordPage;
