import React, { useEffect, useState } from "react";
import { PinchZoomPan } from "./PinchZoomPan/PinchZoomPan";
import ReactFamilyTree from "react-family-tree";
import css from "./App.module.css";
import { NODE_HEIGHT, NODE_WIDTH } from "../../../pages/ProfilePage/const";
import { FamilyNode } from "./FamilyNode";
import { getNodeStyle } from "../../../pages/ProfilePage/utils";

const FamilyTreeMobile = ({ data }: any) => {
  console.log("zxcdata333",data)
  const [rootId, setRootId] = useState<string | undefined>();
  const [selectId, setSelectId] = useState<string>();
  const [hoverId, setHoverId] = useState<string>();

  useEffect(() => {
    if (data && data.length > 0) {
      setRootId(data[0].id);
    }
  }, [data]);

  return (
    <>
      {data && data?.length > 0 && rootId != null && (
        <PinchZoomPan
          min={0.5}
          max={2.5}
          captureWheel
          className={css.wrapper_mobile}
        >
          <ReactFamilyTree
            nodes={data}
            rootId={rootId}
            width={NODE_WIDTH}
            height={NODE_HEIGHT}
            className={css.tree}
            renderNode={(node: any) => (
              <FamilyNode
                key={node.id}
                node={node}
                image={node?.avatar}
                isRoot={node.id === rootId}
                isHover={node.id === hoverId}
                onClick={setSelectId}
                style={getNodeStyle(node)}
                onSubClick={setRootId}
              />
            )}
          />
        </PinchZoomPan>
      )}
    </>
  );
};

export default FamilyTreeMobile;
