import {
  Button,
  Card,
  Center,
  Chip,
  Flex,
  Group,
  Image,
  Loader,
  PasswordInput,
  Text,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import restClient from "../../api/restClient";
import PhoneInput from "react-phone-number-input";
import "./styles.css";
import "react-phone-number-input/style.css";

const InvitePage = ({ props }: any) => {
  const { classes } = useStyles();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [gender, setGender] = useState("m");
  console.log("zxcgender", gender);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone]: any = useState();
  const [disabled, setDisabled] = useState(true);
  // const [submitButton, setSubmitButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchParams]: any = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);

  useEffect(() => {
    setLoading(true);
    restClient
      .get(`/invite-member/${urlParams.userId}`)
      .then((response): any => {
        setEmail(response?.data?.data?.email);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((error) => console.log("zxcerror", error));
  }, []);

  useEffect(() => {
    if (
      password.length < 6 ||
      name.length < 2 ||
      surname.length < 2 ||
      phone?.length < 10
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [password, name, surname, phone]);

  const submit = () => {
    setSubmitLoading(true);
    setDisabled(true);
    restClient
      .post(`/auth/local/register`, {
        email: email,
        username: email,
        password: password,
      })
      .then((response) => {
        restClient
          .post(`/user-profiles`, {
            data: {
              name: name,
              surname: surname,
              user: response.data.user.id,
              phone: phone,
            },
          })
          .then((response) => {
            restClient
              .post(`/members-update`, {
                name: name,
                id: urlParams?.userId,
                gender: gender,
                displayName: name + " " + surname,
                surname: surname,
              })
              .then((response) => {
                setSubmitLoading(false);
                setDisabled(false);
                setSuccess(true);
              })
              .catch((error) => {
                setSubmitLoading(false);
                setDisabled(false);
                console.log("zxcerror", error);
              });
          })
          .catch((error) => {
            setSubmitLoading(false);
            setDisabled(false);
            console.log("zxcerror", error);
          });
      })
      .catch((error) => {
        setSubmitLoading(false);
        setDisabled(false);
        console.log("zxcerror", error);
      });
  };
  return (
    <Center h="100vh">
      <Card
        className={classes.card}
        shadow="sm"
        padding="lg"
        bg={success ? "green" : "white"}
        radius="md"
        withBorder
      >
        <Center>
          <Flex direction="column" align="center">
            <Image
              fit="contain"
              height={"20vh"}
              src={require("../../assets/logo.png")}
            />
            <Title fz={20}>Complete your registration</Title>
          </Flex>
        </Center>
        {loading ? (
          <Center mt={20}>
            <Loader size={50} />
          </Center>
        ) : success ? (
          <Center mt={20}>
            <Text fw={600} color="white">
              Congratulations, your registration has been created successfully.
            </Text>
          </Center>
        ) : (
          <Flex gap={15} direction="column">
            <TextInput
              value={name}
              required
              onChange={(e: any) => setName(e.target.value)}
              label="Name"
              placeholder="Name"
            />
            <TextInput
              value={surname}
              onChange={(e: any) => setSurname(e.target.value)}
              required
              label="Surname"
              placeholder="Surname"
            />
            <PhoneInput
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
            />
            <Title fz={14} fw={400}>
              Gender
            </Title>
            <Chip.Group multiple={false} value={gender} onChange={setGender}>
              <Group>
                <Chip
                  variant="filled"
                  styles={{ label: { border: "1px solid gray" } }}
                  value="m"
                >
                  Man
                </Chip>
                <Chip
                  variant="filled"
                  styles={{ label: { border: "1px solid gray" } }}
                  color={"pink"}
                  value="w"
                >
                  Women
                </Chip>
              </Group>
            </Chip.Group>
            <TextInput
              value={email}
              label="Email"
              disabled
              placeholder="Email"
            />
            <PasswordInput
              value={password}
              // error={submitButton}
              onChange={(e: any) => setPassword(e.target.value)}
              label="Password"
              description="Min 6 characters"
              placeholder="Password"
              required
            />
            <Button
              onClick={submit}
              disabled={disabled || submitLoading}
              loading={submitLoading}
              color="green"
              h={60}
              mt={20}
            >
              Submit
            </Button>
          </Flex>
        )}
      </Card>
    </Center>
  );
};
const useStyles = createStyles((theme) => ({
  card: {
    width: "40vw",
    [`@media (max-width: 978px)`]: {
      width: "90vw",
    },
  },
}));
export default InvitePage;
